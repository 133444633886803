import React, { useEffect } from 'react';
import './Promotion.scss';
import Axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const Promotion = () => {
  const { decodeS1 } = useAuth();

  // 상담버튼관련 로그따로저장
  const handleButtonClick = async (type) => {
    const uid = decodeS1() || null; // 로그인 상태 확인
    const currentUrl = window.location.href; // 현재 URL

    try {
      console.log('전송 데이터:', {
        buttonType: type,
        event: 'button_click',
        url: currentUrl,
        uid,
        timestamp: new Date().toISOString(),
      });

      await Axios.post('https://www.wlcmanager.com:8443/api/post/log', {
        buttonType: type, // 버튼 종류
        event: 'button_click',
        url: currentUrl, // 현재 URL
        uid, // 로그인 상태에 따라 uid 또는 NULL 전송
        timestamp: new Date().toISOString(), // 현재 시간
      });

      console.log(`로그 저장 성공: ${type} 버튼 클릭`);
    } catch (error) {
      console.error(`로그 저장 실패: ${error}`);
    }
  };

  return (
    <div className="promotion-container">
      <div className="scroll-container">
        {/* <div className="scroll-section first">
          <video className="promotion-video" autoPlay loop muted>
            <source src="/video/promotionmv.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div> */}
        <p className="fitst-txt">
          <span>
            시중가 대비 <span style={{ color: '#58d57a', fontWeight: 'bold' }}>50%</span>이상 저렴한 건강검진 기회!
          </span>
        </p>
        <div className="scroll-section second">
          <div className="second-section">
            <div className="promotion-logo-wrap">
              <div className="promotion-logo"></div>
            </div>
            <div style={{ padding: '0 20px', marginTop: '10px' }}>
              <h2>
                결혼 준비, 건강은 <span style={{ color: '#447efa' }}>체크</span>하셨나요?
              </h2>
              <h4>
                평생을 함께할 소중한 사람과의 시작,
                <br />
                서로의 건강을 확인하는 것부터 시작해보세요.
              </h4>

              {/* <div style={{ borderBottom: '2px solid #c6c6c6' }}></div> */}

              <div className="box1">
                <div className="img-wrap">
                  <h3>
                    <span className="check_ok"></span>결혼 전 건강검진의 중요성
                  </h3>
                  <p>평생의 동반자와 시작하는 건강한 약속</p>
                </div>
              </div>
              <div className="box2">
                <div className="img-wrap">
                  <h3>
                    <span className="check_ok"></span>양가 부모님의 건강관리
                  </h3>
                  <p>소중한 부모님께 드리는 특별한 선물</p>
                </div>
              </div>
              <div className="box3">
                <div className="img-wrap">
                  <h3>
                    <span className="check_ok"></span>조기 발견의 중요성
                  </h3>
                  <p>건강한 미래를 위한 현명한 준비</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="scroll-section third">
          <div className="third-section">
            <h2>
              메디코드가 제안하는 <span style={{ color: '#447efa' }}>해결책</span>
            </h2>
            <div class="box4">
              <div className="img2-wrap">
                <span className="sp1"></span>
              </div>
              <h3>예비부부 맞춤검진</h3>
              <p>결혼 준비에 꼭 필요한 필수 검진항목으로 구성된 특별 검진상품</p>
            </div>
            <div class="box5">
              <div className="img2-wrap">
                <span className="sp2"></span>
              </div>
              <h3>효도검진</h3>
              <p>양가 부모님과 함께하는 가족 맞춤형 건강검진</p>
            </div>
            <div class="box6">
              <div className="img2-wrap">
                <span className="sp3"></span>
              </div>
              {/* <div class="background"></div> */}
              <h3>편리한 원스톱 예약</h3>
              <p>전문 상담사가 검진 일정과 항목을 맞춤 설계</p>
            </div>
          </div>
        </div>

        <div className="scroll-section">
          <div className="plus-section">
            <div className="image-box responsive-img1">
              <div className="responsive-txt">
                메디코드의 대표 검진 상품을
                <br />
                소개합니다
              </div>
            </div>
          </div>
          <div className="product-section">
            <div className="image-wrap">
              <div className="product-img4"></div>
              <div className="product-title">SMART 패키지</div>
              <div className="product-txt">합리적인 가격으로 최상의 서비스를 받아보세요</div>
              <div className="product-price">
                <div className="normal-price">기존가격: 50만원 상당</div>
                <div className="sale-price">할인가격: ₩50,000</div>
              </div>
            </div>
            <div className="image-wrap">
              <div className="product-img2"></div>
              <div className="product-title">PREMIUM 패키지</div>
              <div className="product-txt">합리적인 가격으로 최상의 서비스를 받아보세요</div>
              <div className="product-price">
                <div className="normal-price">기존가격: 100만원 상당</div>
                <div className="sale-price">할인가격: ₩240,000</div>
              </div>
            </div>
            <div className="image-wrap">
              <div className="product-img1"></div>
              <div className="product-title">VIP 패키지</div>
              <div className="product-txt">합리적인 가격으로 최상의 서비스를 받아보세요</div>
              <div className="product-price">
                <div className="normal-price">기존가격: 150만원 상당</div>
                <div className="sale-price">할인가격: ₩340,000</div>
              </div>
            </div>
            <div className="image-wrap">
              <div className="product-img3"></div>
              <div className="product-title">NOBLESS 패키지</div>
              <div className="product-txt">각종 암 등 대표질병 진단을 위한 패키지입니다</div>
              <div className="product-price">
                <div className="normal-price">기존가격: 250만원 상당</div>
                <div className="sale-price">할인가격: ₩410,000</div>
              </div>
            </div>
            <div className="all-product-wrap">
              <div className="all-product-content">
                모든상품은&nbsp;
                <a href="http://www.medicode.co.kr/" target="_blank">
                  <span>메디코드</span>
                </a>
                에서
                <br /> 확인하실 수 있습니다
              </div>
            </div>
          </div>
        </div>

        <div className="scroll-section fourth">
          <div className="fourth-section">
            <h2>
              건강한 결혼 생활을 위한 <span style={{ color: '#447EFA', fontSize: '1.3em' }}>체크포인트</span>
            </h2>
            <div className="box7">
              <h3 className="tx-title1">예비부부 검진</h3>
              <h4>새로운 시작을 위한 기본 건강 체크</h4>
              <div className="center-txt-left">
                <p>
                  <span className="checkinbox"></span> 임신 전 필수 검진 항목
                </p>
                <p>
                  <span className="checkinbox"></span> 예비부부 맞춤 상담
                </p>
                <p>
                  <span className="checkinbox"></span> 기초 건강 검진
                </p>
              </div>
            </div>
            <div className="box8">
              <h3 className="tx-title1">효도검진</h3>
              <h4>부모님의 건강한 노후를 위한 선물</h4>
              <div className="center-txt-left">
                <p>
                  <span className="checkinbox"></span> 연령별 맞춤 검진
                </p>
                <p>
                  <span className="checkinbox"></span> 심층 건강 상담
                </p>
                <p>
                  <span className="checkinbox"></span> 정밀 검진 항목
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="scroll-section fifth">
          <div className="fifth-section">
            <h2>많은 예비부부가 선택한 메디코드</h2>
            <div className="box9">
              <p>⭐⭐⭐⭐⭐</p>
              <h3>김0연 예비부부</h3>
              <p>결혼 전 양가 부모님과 함께 건강검진을 받았어요. 전문적인 의료진의 상세한 설명이 특히 좋았습니다.</p>
            </div>
            <div className="box10">
              <p>⭐⭐⭐⭐⭐</p>
              <h3>이0호 커플</h3>
              <p>
                건강검진을 통해 미처 몰랐던 건강 상태를 확인하고 관리할 수 있게 되었어요. 결혼 준비 중 현명한
                선택이었습니다.
              </p>
            </div>
            <div className="box11">
              <p>⭐⭐⭐⭐⭐</p>
              <h3>박0민 예비부부</h3>
              <p>
                부모님께서 특히 만족하셨어요. 검진 항목도 다양하고 의료진들도 친절해서 편안하게 검진받을 수 있었습니다.
              </p>
            </div>
          </div>
        </div>
        <div className="scroll-section sixth">
          <div className="sixth-section">
            <div className="box12">
              <h2>
                건강한 결혼 생활의 시작
                <br />
                지금 예약해보세요
              </h2>
              <p>
                <span style={{ color: '#447EFA' }}>전문 상담사</span>가 <br />
                <span style={{ color: '#447EFA' }}>맞춤형 검진 설계</span>를 도와드립니다
              </p>
              <div class="animation-box">
                <div class="arrow-container">
                  <span class="arrow"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 하단 상담예약 고정 */}
      <div className="fixed-reserv">
        <a
          //href="https://www.wlcmanager.com/self/1i9ist11cJEB"
          href="https://naver.me/FJbmaJWk"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed-left-box"
          onClick={() => handleButtonClick('reservation')}
        >
          <span className="promotion_reserv"></span> &nbsp;&nbsp;&nbsp;
          <span style={{ letterSpacing: '1.2px' }}>상담예약</span>
        </a>
        <a className="fixed-right-box" href="tel:010-9915-5706" onClick={() => handleButtonClick('phone')}>
          <span className="promotion_tel"></span> &nbsp;&nbsp;<span style={{ letterSpacing: '1.2px' }}>상담전화</span>
        </a>
      </div>
    </div>
  );
};

export default Promotion;
