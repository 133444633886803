import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReservContext } from '../../contexts/ReservContext';
import axios from 'axios';
import Tel from '../Common/Tel';

const SelfReservation = () => {
  const { clearReservData, keepReservData, setProduct, setSelfUrl, selfUrl } = useReservContext();
  const navigation = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const status = location.state?.status || 'default';

  const [collaboData, setCollaboData] = useState([]);

  const getCollaboData = async () => {
    try {
      const uid = pathname.replace('/self/', '');
      const response = await axios.get('https://www.wlcmanager.com:8443/api/get/collabo_data', {
        params: { uid },
      });

      setCollaboData(response.data);
    } catch (error) {
      console.error('Error fetching collabo data:', error);
    }
  };

  useEffect(() => {
    if (status !== 'keep' && status !== 'default') {
      clearReservData();
    } else if (status === 'keep') {
      keepReservData();
    }

    setSelfUrl(pathname);

    // 콜라보 데이터 가져오기
    getCollaboData();
  }, [location]);

  const movePage = (path) => {
    setProduct('');
    navigation(`${selfUrl}${path}`, {
      state: {
        selfUrl: selfUrl,
      },
    });
  };

  return (
    <div className="reserv_wrap self">
      <div className="reserv_back main">
        <div className="reserv_top_box">
          <div className="reserv_collabo">
            <div className="collabo-left-box">메디코드</div>
            <div className="collabo-center">X</div>
            {/* collaboData가 비어있지 않으면 회사명 표시 */}
            <div className="collabo-right-box">{collaboData[0]?.company_name || '회사명 없음'}</div>
          </div>
          <div className="reserv_title">예약 시작</div>
          <div className="reserv_title sub">이용하기 원하시는 메뉴를 선택하세요.</div>
        </div>
        <div className="reserv_bottom_box">
          <div className="reserv_menu_box" onClick={() => movePage('/category')}>
            <div className="menu_inner_box">
              <div className="menu_icon"></div>
              <div className="menu_text">상품찾기</div>
            </div>
          </div>
          <div
            className="reserv_menu_box hospital"
            onClick={() => movePage('/hospital', { state: { sector: 'sector2' } })}
          >
            <div className="menu_inner_box">
              <div className="menu_icon second"></div>
              <div className="menu_text">병원보기</div>
            </div>
          </div>
        </div>
      </div>
      <Tel />
    </div>
  );
};

export default SelfReservation;
