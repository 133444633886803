import React, { useEffect, useState } from 'react';
import Clock from '../Common/Clock';
import ApexCharts from 'react-apexcharts';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import Calendar from 'react-calendar'; // react-calendar import
import 'react-calendar/dist/Calendar.css'; // react-calendar 스타일 import
import dayjs from 'dayjs'; // dayjs import

const Statistics = () => {
  const { decodeS1 } = useAuth(); // decodeS1 가져오기

  const [isStartDateOpen, setIsStartDateOpen] = useState(false); // 시작 날짜 달력 열림 상태
  const [isEndDateOpen, setIsEndDateOpen] = useState(false); // 종료 날짜 달력 열림 상태

  const [buttonType, setButtonType] = useState(''); // 필터 상태 추가
  const [activeButton, setActiveButton] = useState(''); // 활성화된 버튼 상태 추가
  const [startDate, setStartDate] = useState(null); // 시작 날짜 상태
  const [endDate, setEndDate] = useState(null); // 종료 날짜 상태

  const [totalLogs, setTotalLogs] = useState(0); // Total logs 상태
  const [todayLogs, setTodayLogs] = useState(0); // Today logs 상태

  const [yAxisMax, setYAxisMax] = useState(50); // y축 max 값
  const [yAxisMin, setYAxisMin] = useState(0); // y축 min 값

  const [chartData, setChartData] = useState({
    series: [
      { name: '랜딩페이지', data: [] },
      { name: '상담예약', data: [] },
      { name: '상담전화', data: [] },
      { name: '전체', data: [] }, // 전체 데이터를 위한 시리즈 추가
    ],
    options: {
      chart: { type: 'bar', height: 500 },
      xaxis: { categories: [] },
      yaxis: {
        min: 0,
        max: 50, // 기본 값 50으로 설정
        tickAmount: 5, // y축의 눈금 개수 늘림
        labels: {
          formatter: (value) => `${value}`,
        },
      },
      title: {
        text: '접속현황',
        align: 'left',
        style: { color: '#333' },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '45%', // 바의 두께를 늘려서 잘 보이게 함
        },
      },
      dataLabels: {
        enabled: true, // 데이터 값 표시 활성화
        style: {
          fontSize: '12px',
          colors: ['#333'],
        },
        formatter: (value) => {
          // 0일 때는 값을 표시하지 않음
          return value === 0 ? '' : value;
        },
      },
    },
  });

  const processLogsData = (logs, startDate, endDate) => {
    const categories = [];
    const counts = [];

    // 날짜 범위 생성
    let current = dayjs(startDate);
    const end = dayjs(endDate);

    while (current.isBefore(end) || current.isSame(end)) {
      categories.push(current.format('YYYY-MM-DD')); // 날짜 형식: YYYY-MM-DD
      counts.push(0); // 초기화된 count 데이터
      current = current.add(1, 'day'); // 하루씩 증가
    }

    logs.forEach((log) => {
      const logDate = dayjs(log.log_date).format('YYYY-MM-DD');
      const index = categories.indexOf(logDate);
      if (index !== -1) {
        counts[index] += log.count; // 해당 날짜에 count 추가
      }
    });

    return { counts, categories };
  };

  const fetchLogs = async (filterType = '', startDate = '', endDate = '') => {
    try {
      const params = {
        start_date: startDate || undefined,
        end_date: endDate || undefined,
      };

      let seriesData = [];
      let categories = [];
      let totalLogCount = 0;
      let todayLogCount = 0;

      // 오늘 날짜 계산
      const today = dayjs().format('YYYY-MM-DD');

      // 기본 색상 설정
      let titleColor = '#333'; // 기본 색상
      let seriesColors = ['#008FFB', '#FEB019', '#00E396', '#FF4560']; // 전체 색상 기본값

      if (filterType === 'landing') {
        titleColor = '#008FFB'; // 랜딩페이지 색상
        seriesColors = ['#008FFB'];
      } else if (filterType === 'reservation') {
        titleColor = '#FEB019'; // 상담예약 색상
        seriesColors = ['#FEB019'];
      } else if (filterType === 'phone') {
        titleColor = '#00E396'; // 상담전화 색상
        seriesColors = ['#00E396'];
      }

      if (filterType === '') {
        // 전체 데이터 요청
        const [responseLanding, responseReservation, responsePhone] = await Promise.all([
          axios.get('https://www.wlcmanager.com:8443/api/get/logs', { params: { ...params, button_type: 'landing' } }),
          axios.get('https://www.wlcmanager.com:8443/api/get/logs', {
            params: { ...params, button_type: 'reservation' },
          }),
          axios.get('https://www.wlcmanager.com:8443/api/get/logs', { params: { ...params, button_type: 'phone' } }),
        ]);

        const { counts: countsLanding, categories: landingCategories } = processLogsData(
          responseLanding.data,
          startDate,
          endDate
        );
        const { counts: countsReservation } = processLogsData(responseReservation.data, startDate, endDate);
        const { counts: countsPhone } = processLogsData(responsePhone.data, startDate, endDate);

        categories = landingCategories;
        const totalCounts = countsLanding.map((count, index) => count + countsReservation[index] + countsPhone[index]);

        // 전체 로그 수 계산
        totalLogCount = totalCounts.reduce((acc, count) => acc + count, 0); // 날짜별 합산

        // 오늘의 로그 수 계산 (오늘 날짜에 해당하는 값만 추출)
        const todayIndex = categories.indexOf(today); // 오늘 날짜의 인덱스 찾기
        todayLogCount = todayIndex !== -1 ? totalCounts[todayIndex] : 0; // 오늘 날짜에 해당하는 값이 있으면 그 값을 사용

        seriesData = [
          { name: '전체', data: totalCounts },
          { name: '랜딩페이지', data: countsLanding },
          { name: '상담예약', data: countsReservation },
          { name: '상담전화', data: countsPhone },
        ];
      } else {
        // 특정 버튼 타입 데이터 요청
        const response = await axios.get('https://www.wlcmanager.com:8443/api/get/logs', {
          params: { ...params, button_type: filterType },
        });

        const { counts, categories: filterCategories } = processLogsData(response.data, startDate, endDate);

        categories = filterCategories;
        seriesData = [
          {
            name: filterType === 'landing' ? '랜딩페이지' : filterType === 'reservation' ? '상담예약' : '상담전화',
            data: counts,
          },
        ];

        // 특정 필터의 전체 로그 수 계산
        totalLogCount = counts.reduce((acc, count) => acc + count, 0); // 필터된 데이터의 합산

        // 오늘의 로그 수 계산 (오늘 날짜에 해당하는 값만 추출)
        const todayIndex = categories.indexOf(today); // 오늘 날짜의 인덱스 찾기
        todayLogCount = todayIndex !== -1 ? counts[todayIndex] : 0; // 오늘 날짜에 해당하는 값이 있으면 그 값을 사용
      }

      // 데이터 상태 업데이트
      setTotalLogs(totalLogCount);
      setTodayLogs(todayLogCount);

      setChartData((prevState) => ({
        ...prevState,
        series: seriesData,
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories, // 범위 내 날짜를 x축으로 설정
            title: {
              text:
                filterType === ''
                  ? ''
                  : filterType === 'landing'
                  ? '[ 랜딩페이지 통계 ]'
                  : filterType === 'reservation'
                  ? '[ 상담예약 통계 ]'
                  : '[ 상담전화 통계 ]',
              style: {
                color: titleColor, // 동적으로 설정된 색상
                fontSize: '14px',
                fontWeight: 'bold',
              },
            },
          },
          colors: seriesColors, // 동적으로 설정된 색상
          legend: {
            show: true, // 범례 활성화
            position: 'bottom', // 범례 위치
            labels: {
              colors: '#333',
              useSeriesColors: true, // 시리즈별 색상 사용
            },
          },
        },
      }));
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  const handleFilterChange = (type) => {
    setButtonType(type); // buttonType 상태 업데이트
    setActiveButton(type); // 클릭된 버튼을 활성화

    // 버튼 타입에 따라 데이터를 요청
    if (type === '') {
      fetchLogs('', startDate, endDate); // 전체 데이터 요청
    } else {
      fetchLogs(type, startDate, endDate); // 해당 타입 데이터만 요청
    }
  };

  const handleIncreaseMax = () => {
    setYAxisMax((prevMax) => prevMax + 10);
  };

  const handleDecreaseMax = () => {
    setYAxisMax((prevMax) => prevMax - 10);
  };

  const handleStartDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setStartDate(formattedDate);
    setIsStartDateOpen(false);
    if (endDate) {
      fetchLogs(buttonType, formattedDate, endDate); // 날짜 범위에 맞는 데이터 요청
    }
  };

  const handleEndDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setEndDate(formattedDate);
    setIsEndDateOpen(false);
    if (startDate) {
      fetchLogs(buttonType, startDate, formattedDate); // 날짜 범위에 맞는 데이터 요청
    }
  };

  useEffect(() => {
    const today = dayjs();
    const defaultStartDate = today.subtract(3, 'day').format('YYYY-MM-DD'); // 오늘 기준 -3일
    const defaultEndDate = today.add(3, 'day').format('YYYY-MM-DD'); // 오늘 기준 +3일

    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);

    fetchLogs(buttonType, defaultStartDate, defaultEndDate); // 초기 데이터 로드
  }, []);

  const handleResetDates = () => {
    const today = dayjs();
    const defaultStartDate = today.subtract(3, 'day').format('YYYY-MM-DD');
    const defaultEndDate = today.add(3, 'day').format('YYYY-MM-DD');

    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
    setButtonType(''); // 전체 필터로 초기화
    setActiveButton(''); // 버튼 상태도 초기화
    fetchLogs('', defaultStartDate, defaultEndDate); // 초기 날짜 범위로 데이터 로드
  };

  useEffect(() => {
    setChartData((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        yaxis: {
          ...prevState.options.yaxis,
          max: yAxisMax, // Y축 최대값을 yAxisMax 상태로 업데이트
        },
      },
    }));
  }, [yAxisMax]);

  // useEffect에서 초기화를 위한 호출
  useEffect(() => {
    handleResetDates(); // 초기 데이터 로드
  }, []);

  return (
    <div className="statistics-wrap">
      <div className="statistics-section1">
        <div>
          <span className="cblue">호스팅 만료일</span>: <span className="cred">YYYY-MM-DD (~일 남았습니다.)</span>
        </div>
        <div>
          <span className="cblue">호스팅 업체</span>: <span className="cred">NAVER CLOUDE PLATFORM</span>
        </div>
        <div>만료일이 지나면 모든 서비스가 중단되어 홈페이지를 이용할 수 없습니다.</div>
        <br />
        <div>
          <span className="cblue">SSL 인증서 만료기간</span>:{' '}
          <span className="cred">2025년 4월 30일 수요일 오전 8:59:59</span>
        </div>
        <div>
          <span className="cblue">발급 기관</span>: DigiCert
        </div>
      </div>

      <div className="statistics-section2">
        <div className="live-data">
          <div className="clock-wrap">
            <Clock />
          </div>
          <div className="data-wrap">
            Total: <span className="c1">{totalLogs}</span> Today: <span className="c2">{todayLogs}</span>
          </div>
        </div>
        <div className="filter-buttons">
          <button onClick={() => handleFilterChange('')} className={activeButton === '' ? 'active' : ''}>
            전체
          </button>
          <button onClick={() => handleFilterChange('landing')} className={activeButton === 'landing' ? 'active' : ''}>
            랜딩페이지
          </button>
          <button
            onClick={() => handleFilterChange('reservation')}
            className={activeButton === 'reservation' ? 'active' : ''}
          >
            상담예약
          </button>
          <button onClick={() => handleFilterChange('phone')} className={activeButton === 'phone' ? 'active' : ''}>
            상담전화
          </button>
        </div>

        {/* 시작 날짜와 종료 날짜 선택 */}
        <div className="date-picker-wrap">
          <div className="search-date-wrap">
            <div className="search-calendar-btn">
              <button onClick={() => setIsStartDateOpen(!isStartDateOpen)}>{startDate || '시작 날짜 선택'}</button>
              {isStartDateOpen && (
                <Calendar onChange={handleStartDateChange} value={startDate ? new Date(startDate) : null} />
              )}
            </div>
            &nbsp;~&nbsp;
            <div className="search-calendar-btn">
              <button onClick={() => setIsEndDateOpen(!isEndDateOpen)}>{endDate || '종료 날짜 선택'}</button>
              {isEndDateOpen && <Calendar onChange={handleEndDateChange} value={endDate ? new Date(endDate) : null} />}
            </div>
            <button className="cal-reset-btn" onClick={handleResetDates}>
              <span>날짜 초기화</span>
            </button>
          </div>
        </div>

        <div className="chart-controls">
          <button className="data-add" onClick={handleIncreaseMax}>
            Y축 최대 인원수 증가 +
          </button>
          <button className="data-minus" onClick={handleDecreaseMax}>
            Y축 최대 인원수 감소 -
          </button>
        </div>
        <div>
          <ApexCharts options={chartData.options} series={chartData.series} type="bar" height={500} />
        </div>
      </div>

      <div className="statistics-section3">{/* Reserved for additional content */}</div>
    </div>
  );
};

export default Statistics;
