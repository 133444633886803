import './styles/index.scss';
import Router from './router/Router';
import Login from './components/Login/Login';
import RegisterStep1 from './components/Register/RegisterStep1';
import RegisterStep2 from './components/Register/RegisterStep2';
import Aside from './components/Header/Aside';
import Header from './components/Header/Header';
import React, { Fragment, useEffect, useState } from 'react';
import { useAuth } from './contexts/AuthContext';
import { Route, Routes, useLocation } from 'react-router-dom';
import Search from './components/Search/Search';
import Footer from './components/Footer/Footer';
import SelfReservation from './components/Self/SelfReservation';
import SelfSearchProduct from './components/Self/SelfSearchProduct';
import SelfSearchHospital from './components/Self/SelfSearchHospital';
import SelfSelectDate from './components/Self/SelfSelectDate';
import SelfReservCustomer from './components/Self/SelfReservCustomer';
import SelfReservCheck from './components/Self/SelfReservCheck';
import SelfSuccess from './components/Self/SelfSuccess';
import SelfSearchCategory from './components/Self/SelfSearchCategory';
import SelfSelectDate2 from './components/Self/SelfSelectDate2';
import Promotion from './pages/Promotion';
import Axios from 'axios';

function App() {
  const { loginAccess, login, decodeS1 } = useAuth(); // 로그인 여부 확인
  const location = useLocation(); // 현재 경로 가져오기
  const path = location.pathname;
  const parts = path.split('/');
  const target = parts[1];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // 윈도우 리사이즈 핸들러
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 로그인 상태 확인
  useEffect(() => {
    const loginStatus = localStorage.getItem('Access');
    if (loginStatus === 'true') {
      login();
    }
  }, []);

  // 모든 경로 변경 시 로그 기록
  useEffect(() => {
    const logPageVisit = async () => {
      const uid = decodeS1() || null; // decodeS0() 값이 없으면 NULL로 설정
      const currentUrl = window.location.href;

      // 특정 URL(`/promotion`)에만 로그 기록
      if (currentUrl === 'https://www.wlcmanager.com/promotion') {
        try {
          // 서버로 로그 전송
          await Axios.post('https://www.wlcmanager.com:8443/api/post/log', {
            buttonType: 'landing',
            event: 'page_visit',
            url: currentUrl,
            uid, // 로그인 상태에 따라 uid 또는 NULL 전송
            timestamp: new Date().toISOString(),
          });

          // 로그 상태 출력
          if (uid === null) {
            console.log('로그인 상태가 아닙니다. NULL로 로그를 기록합니다.');
          } else {
            console.log(`로그인 상태입니다. uid: ${uid}로 로그를 기록합니다.`);
          }
        } catch (error) {
          console.error('로그 저장 중 오류 발생:', error);
        }
      }
    };

    // 로그인 상태 변화 및 경로 감지 시 로그 기록
    if (loginAccess !== undefined) {
      logPageVisit();
    }
  }, [loginAccess, decodeS1, location.pathname]);

  return (
    <Fragment>
      {loginAccess ? (
        <div className="screen">
          {windowWidth >= 1000 && <Aside />}
          <div className={`main_area`}>
            <Header />
            <Router />
            <Footer />
          </div>
        </div>
      ) : (
        // 로그인 화면 컴포넌트
        <div className="screen">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register/step1" element={<RegisterStep1 />} />
            <Route path="/register/step2" element={<RegisterStep2 />} />
            <Route path="/search" element={<Search />} />
            {/* 링크 라우터 */}
            <Route path="/self/:uid" element={<SelfReservation />}></Route>
            <Route path="/self/:uid/product" element={<SelfSearchProduct />} />
            <Route path="/self/:uid/category" element={<SelfSearchCategory />} />
            <Route path="/self/:uid/date" element={<SelfSelectDate />} />
            <Route path="/self/:uid/date2" element={<SelfSelectDate2 />} />
            <Route path="/self/:uid/hospital" element={<SelfSearchHospital />} />
            <Route path="/self/:uid/customer" element={<SelfReservCustomer />} />
            <Route path="/self/:uid/check" element={<SelfReservCheck />} />
            <Route path="/self/success" element={<SelfSuccess />} />
            {/* 프로모션 전용 promotion 페이지 라우터 */}
            <Route path="/promotion" element={<Promotion />} />
          </Routes>
          {target === 'self' && <Footer />}
        </div>
      )}
    </Fragment>
  );
}

export default App;
